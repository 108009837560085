import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Profile from "./profile/Profile";
import ScrollToTopButton from "./topbutton/Top";
import AboutMe from './aboutMe/aboutMe';
import { StyleProvider } from "../contexts/StyleContext";
import { useLocalStorage } from "../hooks/useLocalStorage";
import "./Myself.scss";



const Myself = () => {
  const darkPref = window.matchMedia("(prefers-color-scheme: dark)");
  const [isDark, setIsDark] = useLocalStorage("isDark", darkPref.matches);

  const changeTheme = () => {
    setIsDark(!isDark);
  };

  return (
    <div className={isDark ? "dark-mode" : null}>
      <StyleProvider value={{ isDark: isDark, changeTheme: changeTheme }}>
        {(
          <>
            <Header />
            <AboutMe />
            <Profile />
            <Footer />
            <ScrollToTopButton />
          </>
        )}
      </StyleProvider>
    </div>
  );
};

export default Myself;