import React, { useContext } from "react";
import { Fade } from "react-reveal";
import emoji from "react-easy-emoji";
import "./aboutMe.scss";

import StyleContext from "../../contexts/StyleContext";

export default function Aboutme() {
    const { isDark } = useContext(StyleContext);
    return (
        <div className="about-main" id="aboutme">
            <div className="aboutme-main">
                <div className="aboutme-text-div">
                    <div class="main-box">
                        <Fade bottom duration={1000} distance="40px">
                            <h1
                                className={isDark ? "dark-mode aboutme-text" : "aboutme-text"}
                            >
                                {"Heya guys, its me... Shryansh! "}
                                <span className="wave-emoji">{emoji("👋")}</span>
                            </h1>
                            <p
                                className={
                                    isDark
                                        ? "dark-mode aboutme-text-p"
                                        : "aboutme-text-p subTitle"
                                }
                            >
                                {"✨ Shryansh Parashar, known as Jaguar000212, embodies the spirit of an average 18-year-old with an exceptional zeal for Software Development. Since childhood, my passion for coding and development has been an unwavering dream, now realized."}
                                <br /> <br />
                                {"🧑🏻‍💻 As a fervent programmer and self-taught developer, I thrive on the pursuit of knowledge and the exploration of cutting-edge technologies. Crafting innovative solutions to real-world problems fuels my drive."}
                                <br /> <br />
                                {"🚀 Currently enrolled in a B.Tech program in CSE at Manipal Institute of Technology, Bengaluru. I actively engage in various projects while seeking new challenges to broaden my horizons."}
                                <br /> <br />
                                {"⚒️ Python serves as my primary language in project development, complemented by proficiency in C, C++, Kotlin, JavaScript, HTML, CSS, and SQL. I possess adeptness with tools such as Unreal Engine and Adobe Creative Suite, having leveraged MongoDB extensively in past projects."}
                                <br /> <br />
                                {"🌱 My trajectory involves deepening my understanding of Artificial Intelligence, Machine Learning, and Data Science. Alongside, I am honing my Game Development skills and am slated to embark on a collaborative game development endeavor. The realm of Web Development beckons me with its vast creative opportunities."}
                                <br /> <br />
                                {"💬 I am open to engaging in conversations spanning technology, gaming, or any other domain. Feel free to reach out via my email,"} <u>{"jaguar000212@gmail.com"}</u>{", or connect with me on Instagram at "} <a href="https://www.instagram.com/jaguar000212/">{"@jaguar000212"}</a>{". Explore my "} <a href="https://github.com/Jaguar000212">{"GitHub"}</a>{" profile to delve deeper into my projects!"}
                                <br /> <br />
                            </p>
                            <img src="https://github.com/Jaguar000212/Jaguar000212/raw/main/static/media/Jaguar000212.png?raw=true" alt="Jaguar000212" width="80%" />
                            <br /> <br />
                        </Fade>
                    </div>
                    <Fade bottom duration={1000} distance="40px">
                        <h2 className={isDark ? "dark-mode techstack-text" : "techstack-text"}>
                            {"🖥️ "}<u>{"Tech Stack"}</u>
                        </h2>
                        <div className="techstack-text-div">
                            <div className="techstack-languages-div">
                                <h3 className={isDark ? "dark-mode techstack-subtext" : "techstack-subtext"}>
                                    {"📚 Languages -"}
                                </h3>
                                <ul className={isDark ? "dark-mode techstack-text-p" : "techstack-text-p"}>
                                    <li>Python</li>
                                    <li>C</li>
                                    <li>C++</li>
                                    <li>Java</li>
                                    <li>Kotlin</li>
                                    <li>JavaScript</li>
                                    <li>HTML</li>
                                    <li>CSS</li>
                                    <li>SQL</li>
                                </ul>
                            </div>
                            <div className="techstack-tools-div">
                                <h3 className={isDark ? "dark-mode techstack-subtext" : "techstack-subtext"}>
                                    {"⚙️ Tools -"}
                                </h3>
                                <ul className={isDark ? "dark-mode techstack-text-p" : "techstack-text-p"}>
                                    <li>Git & GitHub</li>
                                    <li>IBM watsonX</li>
                                    <li>Linux</li>
                                    <li>Adobe Creative Suite</li>
                                    <li>Android Studio</li>
                                    <li>Unreal Engine</li>
                                    <li>MongoDB</li>

                                </ul>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    );
}
