/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Jaguar000212",
  title: "Hello, I'm Shryansh",
  desc1: "A dedicated Software Developer. 🧑🏻‍💻",
  desc2: "With great understanding of Development and its strategies, looking for such exciting opportunities.",
  desc3: "Proficiency in Python and many of its libraries.",
  desc4: "Currently into Application Development with Java & Kotlin.",
  desc5: "Developed a Discord Bot which gained so much popularity. ✨",
  desc6: "Gaming being my Side Quest. 👾",
  resumeLink:
    "https://drive.google.com/file/d/1qgxsT7nbjxfyj_tedW4goHdXccda3ng9/view?usp=sharing", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/Jaguar000212",
  linkedin: "https://www.linkedin.com/in/jaguar000212/",
  gmail: "jaguar000212@gmail.com",
  instagram: "https://www.instagram.com/jaguar000212/",
  twitter: "https://twitter.com/Jaguar000212",
  // gitlab: "https://gitlab.com/saadpasta",
  facebook: "https://www.facebook.com/profile.php?id=100088920641500",
  // medium: "https://medium.com/@Jaguar000212",
  stackoverflow: "https://stackoverflow.com/users/23411058/jaguar000212",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "CRAZY FULL STACK DEVELOPER WHO WANTS TO EXPLORE EVERY TECH STACK",
  skills: [
    emoji("⚡ Python Developer"),
    emoji("⚡ Discord Bot Developer"),
    emoji("⚡ Android Application Developer"),
    emoji("⚡ Web Developer"),
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "Java",
      fontAwesomeClassname: "fab fa-java"
    },
    {
      skillName: "C",
      fontAwesomeClassname: "fab fa-cuttlefish"
    },
    {
      skillName: "Python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "C++",
      fontAwesomeClassname: "fab fa-cuttlefish"
    },
    {
      skillName: "Kotlin",
      fontAwesomeClassname: "fab fa-android"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "HTML-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "CSS",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "SQL Database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "MongoDB",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "Linux",
      fontAwesomeClassname: "fab fa-linux"
    },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Manipal Institute of Technology, Bangalore",
      logo: require("./assets/images/manipalLogo.png"),
      subHeader: "B.Tech in Computer Science",
      duration: "August 2023 - Present",
      desc: "",
      descBullets: [
        "Learning C Language and Data Structures.",
        "Active member of GDSC, a community of developers by Google.",
        "Member of CodeX, the coding club of MIT.",
        "Developing a Game under the guidance of Club Committee.",
        "Earned a lot of Certificates from Coursera and other platforms.",
        "Participated in many Hackathons and Coding Competitions."
      ]
    },
    {
      schoolName: "Subodh Public School, Jaipur",
      logo: require("./assets/images/spsa.png"),
      subHeader: "Intermediate",
      duration: "August 2021 - April 2023",
      desc: "",
      descBullets: [
        "Passed with 91% in PCM.",
        "Perfomed outstanding in Informatics Practices with a score of 97%.",
        "Participated in many Olympiads and Science Competitions.",
        "Learnt Python and libraries like Pandas, Numpy, Matplotlib for Data Science.",
        "Developed a CLI based Wedding Planner using Python."
      ]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: false, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Python Development", //Insert stack or technology you have experience in
      progressPercentage: "100%" //Insert relative proficiency in percentage
    },
    {
      Stack: "SQL",
      progressPercentage: "80%"
    },
    {
      Stack: "Frontend",
      progressPercentage: "50%"
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    },
  ],
  displayCodersrank: true // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Python Developer",
      company: "CodSoft",
      companylogo: require("./assets/images/codsoft.jpg"),
      date: "December 2023 – January 2024",
      desc: "Intern as a Python Developer",
      descBullets: []
    },
    {
      role: "C++ Developer",
      company: "CodSoft",
      companylogo: require("./assets/images/codsoft.jpg"),
      date: "January 2023 – February 2024",
      desc: "Intern as a C++ Developer",
      descBullets: []
    },
    {
      role: "Python Developer",
      company: "CodeAlpha",
      companylogo: require("./assets/images/codealpha.png"),
      date: "February 2024",
      desc: "Intern as a Python Developer",
      descBullets: []
    },
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle: "My Projects which am proud of",
  projects: [
    {
      image: require("./assets/images/WizardiLogo.png"),
      projectName: "Wizardi",
      projectDesc: "An all-round discord bot with tons of features.",
      footerLink: [
        {
          name: "Website",
          url: "https://wizardi.jaguar000212.tech/"
        },
        {
          name: "Source Code",
          url: "https://github.com/Jaguar000212/Wizardi"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/wedding.gif"),
      projectName: "Wedding Planner",
      projectDesc: "A CLI based wedding planner.",
      footerLink: [
        {
          name: "Source Code",
          url: "https://github.com/Jaguar000212/Wedding-Planner"
        }
        //  you can add extra buttons here.
      ]
    },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters",

  achievementsCards: [
    {
      title: "Programming for Everybody\n(Getting Started with Python)",
      subtitle:
        "21 October 2023",
      image: require("./assets/images/michigan.png"),
      imageAlt: "Cerificate",
      footerLink: [
        {
          name: "View Certificate",
          url: "https://coursera.org/verify/DZUCDKCW49X2"
        }
      ]
    },
    {
      title: "Crash Course on Python",
      subtitle:
        "21 October 2023",
      image: require("./assets/images/Google-Symbol.png"),
      imageAlt: "Cerificate",
      footerLink: [
        {
          name: "View Certificate",
          url: "https://coursera.org/verify/XNFB9PYFWDNL"
        }
      ]
    },
    {
      title: "Python Data Structures",
      subtitle:
        "22 October 2023",
      image: require("./assets/images/michigan.png"),
      imageAlt: "Cerificate",
      footerLink: [
        {
          name: "View Certificate",
          url: "https://coursera.org/verify/9EPGHEWECZA8"
        }
      ]
    },
    {
      title: "Python for Data Science, AI & Development",
      subtitle:
        "23 October 2023",
      image: require("./assets/images/ibm.png"),
      imageAlt: "Cerificate",
      footerLink: [
        {
          name: "View Certificate",
          url: "https://coursera.org/verify/XU5KR8V7NDKA"
        }
      ]
    },
    {
      title: "Object-Oriented Programming and GUI with Python",
      subtitle:
        "24 October 2023",
      image: require("./assets/images/asu.png"),
      imageAlt: "Cerificate",
      footerLink: [
        {
          name: "View Certificate",
          url: "https://coursera.org/verify/7K396FUWEFS8"
        }
      ]
    },
    {
      title: "Introduction to Python Programming",
      subtitle:
        "14 November 2023",
      image: require("./assets/images/Penn.png"),
      imageAlt: "Cerificate",
      footerLink: [
        {
          name: "View Certificate",
          url: "https://coursera.org/verify/CVFKJJJ5KEXV"
        }
      ]
    },
    {
      title: "Python Basics",
      subtitle:
        "5 December 2023",
      image: require("./assets/images/michigan.png"),
      imageAlt: "Cerificate",
      footerLink: [
        {
          name: "View Certificate",
          url: "https://coursera.org/verify/WQJR2F8T2AMY"
        }
      ]
    },
    {
      title: "Internship (Python Developer)",
      subtitle:
        "11 December 2023",
      image: require("./assets/images/codsoft_trs.jpg"),
      imageAlt: "CodSoft",
      footerLink: [
        {
          name: "View Letter",
          url: "https://drive.google.com/file/d/1s9ZvmlXNjBTV_LiUa9pTbU0hbhTu6mBT/view?usp=sharing"
        },
        {
          name: "View Certificate",
          url: "https://drive.google.com/file/d/1dIU6ZloPS4rIHyJ2qvIXSCuDNzqwJjJ9/view?usp=sharing"
        }
      ]
    },
    {
      title: "MongoDB Python Developer Path",
      subtitle:
        "28 December 2023",
      image: require("./assets/images/mongodb.png"),
      imageAlt: "Cerificate",
      footerLink: [
        {
          name: "View Certificate",
          url: "https://learn.mongodb.com/c/wrKK37JgQq6lU-oxSjt4gA"
        }
      ]
    },
    {
      title: "Python Functions, Files, and Dictionaries",
      subtitle:
        "29 December 2023",
      image: require("./assets/images/michigan.png"),
      imageAlt: "Cerificate",
      footerLink: [
        {
          name: "View Certificate",
          url: "https://coursera.org/verify/5HKQ9FXYCFBZ"
        }
      ]
    },
    {
      title: "Internship (C++ Developer)",
      subtitle:
        "20 January 2024",
      image: require("./assets/images/codsoft_trs.jpg"),
      imageAlt: "CodSoft",
      footerLink: [
        {
          name: "View Letter",
          url: "https://drive.google.com/file/d/1ngUGFmzy1oIqERh-Ye6Dc2r5Jkbq22ph/view?usp=sharing"
        },
        {
          name: "View Certificate",
          url: "https://drive.google.com/file/d/1D11FZmSho58hob4CMwL5Cdtk5xesa4b4/view?usp=sharing"
        }
      ]
    },
    {
      title: "Internship (Python Developer)",
      subtitle:
        "1 February 2024",
      image: require("./assets/images/codealpha_trs.png"),
      imageAlt: "CodeAplha",
      footerLink: [
        {
          name: "View Letter",
          url: "https://drive.google.com/file/d/1o5tyE7SgOI8X3XqaLNjAD0yC5oO6sqGH/view?usp=sharing"
        },
        {
          name: "View Certificate",
          url: "https://drive.google.com/file/d/1PxUPNRqv8Q05lAbaWpN4-NizUO_Oe73k/view?usp=sharing"
        }
      ]
    },
    {
      title: "Internship (Application Developer)",
      subtitle:
        "06 June 2024",
      image: require("./assets/images/teachnook_trs.png"),
      imageAlt: "Teachnook",
      footerLink: [
        {
          name: "View Letter",
          url: "https://drive.google.com/file/d/1wfM5bDSTebmrvPJNOLrxPS_S1syjFlTj/view?usp=sharing"
        },
        // {
        //   name: "View Certificate",
        //   url: "https://drive.google.com/file/d/1PxUPNRqv8Q05lAbaWpN4-NizUO_Oe73k/view?usp=sharing"
        // }
      ]
    },
  ],
  display: true // Set false to hide this section, defaults to true
};

const isHireable = true; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  isHireable
};
