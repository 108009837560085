import React from "react";
import "./App.scss";
import Home from "./containers/Home";
import { Switch, Route } from 'react-router-dom';
import Myself from './containers/Myself';


const Main = () => (
  <Switch>
    <Route exact path='/' component={Home}></Route>
    <Route exact path='/AboutMe' component={Myself}></Route>
  </Switch>
);




function App() {
  return (
    <div>
      <Main />
    </div>
  );
}

export default App;
